<template>
  <a-form
    v-if="formBuilder.formConfig && formBuilder.list"
    v-bind="formBuilder.formConfig"
    :form="form"
    :selfUpdate="true"
    :style="formBuilder.formConfig.customFormStyle"
  >
    <template v-for="(record, index) in formBuilder.list">
      <slot
        v-if="record.options && !record.options.hide"
        v-show="record.options && record.options.show !== false"
        :name="record.model"
        :record="record"
      >
        <build-block
          v-bind="$attrs"
          v-on="$listeners"
          :key="index"
          :record="record"
          :formConfig="formBuilder.formConfig"
          @change="change"
        >
          <template v-slot="slotProps">
            <slot
              :record="slotProps.record"
              :name="slotProps.record.model"
            />
          </template>
        </build-block>
      </slot>
    </template>
  </a-form>
</template>

<script>
import formMixin from './form-mixin'
export default {
  name: 'AxForm',
  mixins: [formMixin]
}
</script>
